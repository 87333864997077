import { createRouter, createWebHashHistory } from "vue-router";
import Home from "./components/Home.vue";
import Login from "./components/Login.vue";
import Register from "./components/Register.vue";

// lazy-loaded components
const Profile = () => import("./components/Profile.vue");
const BoardAdmin = () => import("./components/BoardAdmin.vue");
const ConfigAdmin = () => import("./components/ConfigAdmin.vue");
const BoardAds = () => import("./components/Ads.vue");
const BoardModerator = () => import("./components/BoardModerator.vue");
const BoardUser = () => import("./components/BoardUser.vue");
const EmailVerification = () => import("./components/EmailVerification.vue");
const EditUserSettings = () => import("./components/EditUserSettings.vue");
const AdGallery = () => import("./components/AdGallery.vue");
const Vehicle360View = () => import("./components/Vehicle360View.vue");
const ActiveSessions = () => import("./components/ActiveSessions.vue");
const Errors = () => import("./components/Errors.vue");

// layout imports
import DefaultLayout from './layouts/DefaultLayout.vue';
import EmptyLayout from './layouts/EmptyLayout.vue';

const routes = [
  // Tvoje obstoječe definicije poti (routes)
  {
    path: "/avto360/:userId/:vehicleId/:imageName",
    name: "vehicle360View",
    component: Vehicle360View,
    props: true,
    meta: { layout: EmptyLayout, title: 'Vehicle 360 View' },
  },
  {
    path: "/",
    name: "home",
    component: Home,
    meta: { layout: DefaultLayout, title: 'DOMOV' },
  },
  {
    path: "/edit-user-settings/:userId",
    name: "editUserSettings",
    component: EditUserSettings,
    meta: { layout: DefaultLayout, title: 'NASTAVITVE UPORABNIKA', requiresAuth: true, roles: ['ROLE_ADMIN'] },
  },
  {
    path: "/home",
    component: Home,
    meta: { layout: DefaultLayout, title: 'DOMOV' },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { layout: DefaultLayout, title: 'PRIJAVA' },
  },
  {
    path: "/register",
    component: Register,
    meta: { layout: DefaultLayout, title: 'REGISTRACIJA' },
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
    meta: { layout: DefaultLayout, title: 'PROFIL', requiresAuth: true },
  },
  {
    path: "/ads",
    name: "ads",
    component: BoardAds,
    meta: { layout: DefaultLayout, title: 'OGLASI', requiresAuth: true, roles: ['ROLE_USER'] },
  },
  {
    path: "/ads/:id",
    name: "adGallery",
    component: AdGallery,
    meta: { layout: DefaultLayout, title: 'UREJANJE OGLASA', requiresAuth: true },
    props: true,
  },

  {
    path: "/admin",
    name: "admin",
    component: BoardAdmin,
    meta: { layout: DefaultLayout, title: 'Klienti', requiresAuth: true, roles: ['ROLE_ADMIN'] },
  },
  {
    path: "/konfiguracija",
    name: "konfiguracija",
    component: ConfigAdmin,
    meta: { layout: DefaultLayout, title: 'GLOBALNE NASTAVITVE', requiresAuth: true, roles: ['ROLE_ADMIN'] },
  },
  {
    path: "/mod",
    name: "moderator",
    component: BoardModerator,
    meta: { layout: DefaultLayout, title: 'Moderator Board', requiresAuth: true, roles: ['ROLE_MODERATOR'] },
  },
  {
    path: "/user",
    name: "user",
    component: BoardUser,
    meta: { layout: DefaultLayout, title: 'User Board', requiresAuth: true, roles: ['ROLE_USER'] },
  },
  {
    path: '/verify-email',
    name: 'EmailVerification',
    component: EmailVerification,
    meta: { layout: DefaultLayout, title: 'Email Verification' },
  },
  {
    path: '/reports/active-sessions',
    name: 'activeSessions',
    component: ActiveSessions,
    meta: { layout: DefaultLayout, title: 'AKTIVNE SEJE', requiresAuth: true, roles: ['ROLE_ADMIN'] },
  },
  {
    path: '/reports/errors',
    name: 'errors',
    component: Errors,
    meta: { layout: DefaultLayout, title: 'NAPAKE', requiresAuth: true, roles: ['ROLE_ADMIN'] },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// Preverjanje veljavnosti žetona (seje)
function isTokenExpired() {
  const user = JSON.parse(localStorage.getItem('user'));
  if (!user || !user.accessToken) {
    return true;
  }

  const decodedToken = decodeJwt(user.accessToken);
  if (!decodedToken) {
    return true; // Če dekodiranje ne uspe, obravnavamo žeton kot neveljaven
  }

  return decodedToken.exp * 1000 < Date.now(); // Preverimo, če je žeton potekel
}

router.beforeEach((to, from, next) => {
  console.log('Začetek usmerjanja');
  console.log(`Usmeritev iz ${from.fullPath} v ${to.fullPath}`);

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const user = JSON.parse(localStorage.getItem('user'));
  const tokenExpired = isTokenExpired();
  const userRoles = user?.roles || [];

  if (requiresAuth && (!user || tokenExpired)) {
    // Uporabnik ni prijavljen ali je žeton potekel
    localStorage.removeItem('user');

    if (to.path !== '/login') {
      // Če trenutno nismo na login strani, preusmeri na login
      return next('/login');
    }
    // Če smo že na login strani, nadaljuj
    return next();
  }

  if (to.meta.roles && !to.meta.roles.some(role => userRoles.includes(role))) {
    // Uporabnik nima ustrezne vloge za dostop do poti
    if (to.path !== '/login') {
      // Če trenutno nismo na login strani, preusmeri na login
      return next('/login');
    }
    return next();
  }

  // Nadaljuj na ciljno pot
  next();
});

// Funkcija za dekodiranje JWT žetona
function decodeJwt(token) {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
            .join('')
    );
    return JSON.parse(jsonPayload);
  } catch (e) {
    console.error('Failed to decode token:', e);
    return null;
  }
}

export default router;

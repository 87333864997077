<template>
  <div class="app-container">
    <!-- Mobilna navigacijska vrstica -->
    <div class="mobile-navbar">
      <div class="navbar-brand">AVTOVIZIJA</div>
      <button @click="toggleMobileMenu" class="mobile-menu-button">
        <font-awesome-icon icon="bars" /> MENU
      </button>
    </div>

    <!-- Navpična navigacijska vrstica -->
    <nav class="navbar-vertical" :class="{ 'is-active': isMobileMenuOpen }">
      <router-link v-if="showAdsBoard" to="/ads" class="nav-link" @click="toggleMobileMenu">OGLASI</router-link>
      <router-link v-if="showAdminBoard" to="/admin" class="nav-link" @click="toggleMobileMenu">KLIENTI</router-link>
      <router-link v-if="showAdminBoard" to="/konfiguracija" class="nav-link" @click="toggleMobileMenu">KONFIGURACIJA</router-link>
      <router-link v-if="!isLoggedIn" to="/register" class="nav-link" @click="toggleMobileMenu">
        <font-awesome-icon icon="user-plus"/>
        REGISTRACIJA
      </router-link>
      <router-link v-if="!isLoggedIn" to="/login" class="nav-link" @click="toggleMobileMenu">
        <font-awesome-icon icon="sign-in-alt"/>
        PRIJAVA
      </router-link>
      <router-link v-if="isLoggedIn" to="/profile" class="nav-link" @click="toggleMobileMenu">
        <font-awesome-icon icon="user"/>
        NASTAVITVE
      </router-link>

      <div v-if="showAdminBoard" class="nav-link" @mouseover="handleMouseOverReports" @mouseleave="handleMouseLeaveReports" @click="handleClickReports">
        <span>
          <font-awesome-icon icon="file-alt"/>
          POROČILA
        </span>
        <div v-if="isReportsMenuVisible" class="sub-menu">
          <router-link to="/reports/active-sessions" class="nav-link sub-nav-link" @click="toggleMobileMenu">AKTIVNE SEJE</router-link>
          <router-link to="/reports/errors" class="nav-link sub-nav-link" @click="toggleMobileMenu">NAPAKE</router-link>
        </div>
      </div>

      <a v-if="isLoggedIn" class="nav-link" @click.prevent="logOutAndCloseMenu">
        <font-awesome-icon icon="sign-out-alt"/>
        ODJAVA
      </a>
    </nav>

    <!-- Glavna vsebina -->
    <div class="main">
      <header>
        <h1>{{ pageTitle }}</h1>
      </header>
      <div class="main-content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DefaultLayout',
  data() {
    return {
      isReportsMenuVisible: false,
      isMobileMenuOpen: false,
      isMobile: false
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      return this.currentUser?.roles?.includes('ROLE_ADMIN') || false;
    },
    showModeratorBoard() {
      return this.currentUser?.roles?.includes('ROLE_MODERATOR') || false;
    },
    showAdsBoard() {
      return this.currentUser?.roles?.includes('ROLE_USER') || false;
    },
    pageTitle() {
      return this.$route.meta.title || 'AVTOVIZIJA';
    }
  },
  methods: {
    toggleMobileMenu() {
      if (window.innerWidth <= 768) {
        this.isMobileMenuOpen = !this.isMobileMenuOpen;
      }
    },
    handleMouseOverReports() {
      if (!this.isMobile) {
        this.isReportsMenuVisible = true;
      }
    },
    handleMouseLeaveReports() {
      if (!this.isMobile) {
        this.isReportsMenuVisible = false;
      }
    },
    handleClickReports() {
      if (this.isMobile) {
        this.isReportsMenuVisible = !this.isReportsMenuVisible;
      }
    },
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    },
    logOutAndCloseMenu() {
      this.logOut();
      this.toggleMobileMenu();
    },
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 768;
    }
  },
  mounted() {
    this.checkIfMobile();
    window.addEventListener('resize', this.checkIfMobile);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkIfMobile);
  }
};
</script>

